import { Outlet, NavLink } from "react-router-dom";
import './styling.css';
import Packages from "../components/Packages";
import Footer from "../components/Footer";
import { TypeAnimation } from 'react-type-animation';

const Layout = () => {
  return (
    <>
      <div className="my-box">
        <div></div>
        <div className="hello-box">
          <h1>Nelson the Dev</h1>
          <nav>
            <div className="topnav">
              {/* NavLinks use lamba functions to set the class. This also works for isPending and isTransitioning */}
                <NavLink to="/" className={
                  ( {isActive} ) => isActive ? "activeLink" : "" } >Home</NavLink>
                <NavLink to="/about" className={
                  ( {isActive} ) => isActive ? "activeLink" : "" }>About</NavLink>
            </div>
          </nav>
          <div>
          <TypeAnimation
            style={{ whiteSpace: 'pre-line', fontFamily: 'Roboto Mono', fontSize: '24px', float: 'left', textAlign:'left'}}
            sequence={[
              `Problem Solver.\nCreative Thinker.\nSoftware Engineer.`,
              1000,
            ]}/>
            </div>
          {/* The outlet is where each 'page' component will be */}
          <Outlet />
        </div>
        <div></div>
        
        <div></div>
        <Packages />
        <div></div>

        <Footer/>
      </div>
    </>
  )
};

export default Layout;