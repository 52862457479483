import { Link } from "react-router-dom";
import './styling.css';

const Home = () => {
    return (
      <>
        <div className="home-div">
            <p style={{marginTop: '100px'}}>I help people dream up their ideal software, <br />then I build it for them.</p>
          
            <Link className="my-button" to="/form">Build Your Dream</Link>
        </div>
      </>
    );
  };
  
  export default Home;