import '../pages/styling.css';
const Footer = () => {
  return (
    <>
      <footer className="my-footer">
            <p> <strong>ABN</strong><br />78 130 761 521 </p>
            <p> <strong>Email</strong><br />nelson@nelsonhain.dev </p>

            <a href="https://www.linkedin.com/in/nelson-hain/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin fa-2x"></i>
            </a>

            <a href="https://www.instagram.com/nelson_the_dev" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram fa-2x"></i>
            </a>
        </footer>
    </>
  );
};
  
  export default Footer;