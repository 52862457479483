import { Link } from "react-router-dom";
import '../pages/styling.css';

const Packages = () => {
    return (
        <div className="packages">
            <section className="package-1">
                <h3>Initial Meeting</h3>
                <p>Discuss and develop a roadmap to bring your dream into reality.</p>
                <p>This is an oppurtunity for me to understand the problems you are facing and to discuss possible solutions.</p>

                <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet"/>
                <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script>
                <Link to="/form" className="meeting-link">Book a 30 Minute Meeting</Link>
            </section>

            <section className="package-2">
                <h3>1 Hour Consultation</h3>
                <p>Bringing our personal dreams into reality can be a daunting task.</p>
                <p>I guide dreamers to develop a roadmap for bringing their ideas into being that reaches beyond the world of technology.</p>

                <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet"/>
                <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script>
                <Link to="/form" className="meeting-link">Book a Dream Consultation Now - $97</Link>
            </section>

            <section className="package-3">
                <h3>Prototyping & Full-Stack App Development</h3>
                <p>Includes business consultation and ongoing bug-support, feature growth & deployment onto the Google Play and Apple iOS stores.</p>
                
                <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet"/>
                <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script>
                <Link to="/form" className="meeting-link">Build Your Dream App</Link>
            </section>
        </div>
    );
  };
  
  export default Packages;