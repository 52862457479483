import './styling.css';

const About = () => {
  return (
      <>
        <div className="about-info">
            <img src={require("../media/nelson.jpg")} alt="Nelson's Profile"></img>
            <p>I first learnt to code 10 years ago. Since then I have dabbled in all kinds of software from games and websites, to AI/ML algorithms and more recently phone apps.<br/>
            Now I help people manifest their dreams in the age of technology.</p>
        </div>
      </>
    );
  };
  
  export default About;