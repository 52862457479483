import './styling.css';
import { useState } from 'react';
import axios from 'axios'; // Import axios library

const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [issue, setIssue] = useState("none");
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [messageText, setMessageText] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
  
    const formData = {
      name,
      email,
      issue,
      message
    };
  
    try {
      const response = await axios.post('https://nelsonhain.dev/PHP/process_form.php', formData); // Use axios to send POST request with form data
  
      console.log(response);
      // Check if request was successful (HTTP status code 2xx)
      if (response.status === 200) { // Check response status instead of 'response.ok'
        // Form submitted successfully
        // Reset form fields
        setName("");
        setEmail("");
        setIssue("none");
        setMessage("");

        setMessageText("Form submitted successfully.");
        console.log(messageText);
      } else {
        // Handle error responses
        setMessageText("An error occurred while submitting the form.");
        console.log(messageText);
      }
    } catch (error) {
      console.error('Network error:', error);
      setMessageText("An error occurred while submitting the form.");
    }
  
    setSubmitting(false);
  };

  return (
    <>
      <div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <fieldset id="contact-info">
            <legend>Contact Me About Your Dream</legend>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} id="name" name="name" required />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} id="email" name="email" required />
            </div>

            <div className="form-group">
              <label htmlFor="issue">How can I help you?</label>
              <select value={issue} onChange={(e) => setIssue(e.target.value)} id="issue" name="issue">
                <option value="none">Please choose one...</option>
                <option value="30min">Initial 30 minute meeting to discuss the problem.</option>
                <option value="idea">I just have an Idea.</option>
                <option value="consultation">I need consultation on a app, business or dream.</option>
                <option value="business">I have a Business which needs an App.</option>
                <option value="prototype">I want to build a Prototype.</option>
                <option value="full-app">I want to build a Full-Stack App.</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="message">Message (max 500 characters):</label>
              <textarea value={message} onChange={(e) => setMessage(e.target.value)} id="message" name="message" rows="5" maxLength="500" required></textarea>
            </div>

            <div className="form-group">
              <input type="submit" value="Submit" disabled={submitting} />
            </div>
          </fieldset>
        </form>
        {messageText && <p className="form-message">{messageText}</p> /* Render the messageText variable */}
      </div>
    </>
  );
};

export default Form;
